export default [
  {
    path: '/invoices',
    name: 'invoices',
    component: () => import('@/views/sales/invoices/Index.vue'),
    meta: {
      pageTitle: 'Gestion des factures',
      breadcrumb: [
        {
          text: 'Factures',
          active: true,
        },
      ],
      requireLogin: true,
    },
  },
  {
    path: '/invoices/create',
    name: 'create-invoice',
    component: () => import('@/views/sales/invoices/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Gestion des factures',
      breadcrumb: [
        {
          text: 'Créer une facture',
          active: true,
        },
      ],
    },
  },
  {
    path: '/invoices/:id',
    name: 'show-invoice-doorly',
    component: () => import('@/views/sales/invoices/ShowDoorly.vue'),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/invoices/:id',
    name: 'show-invoice-rafika',
    component: () => import('@/views/sales/invoices/ShowRafika.vue'),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/invoices/print-all',
    name: 'invoice-print-all',
    props: true,
    component: () => import('@/views/sales/invoices/PrintAll.vue'),
    meta: {
      requireLogin: true,
    },
  },

  {
    path: '/factures/preview/:id',
    name: 'facture-preview',
    props: true,
    component: () => import('@/views/sales/invoices/Preview.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Ajouter factures',
      breadcrumb: [
        {
          text: 'Factures',
        },
        {
          text: 'Ajouter factures',
          active: true,
        },
      ],
    },
  },
]
