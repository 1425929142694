export default [
  {
    path: '/customers',
    name: 'customers',
    component: () => import('@/views/sales/customers/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Gestion des Clients',
      breadcrumb: [
        {
          text: 'Clients',
        },
        {
          text: 'Liste des Clients',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/create',
    name: 'create-customer',
    component: () => import('@/views/sales/customers/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Gestion des Clients',
      breadcrumb: [
        {
          text: 'Clients',
        },
        {
          text: 'Ajouter un Client',
          active: true,
        },
      ],
    },
  },
  {
    path: '/customers/edit/:id',
    name: 'edit-customer',
    props: true,
    component: () => import('@/views/sales/customers/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Gestion des Clients',
      breadcrumb: [
        {
          text: 'Clients',
        },
        {
          text: 'Modifier un Client',
          active: true,
        },
      ],
    },
  },
]
