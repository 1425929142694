export default [
  {
    path: '/estimates',
    name: 'estimates',
    component: () => import('@/views/sales/estimates/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Gestion des devis',
      breadcrumb: [
        {
          text: 'Devis',
        },
        {
          text: 'Liste des devis',
          active: true,
        },
      ],
    },
  },
  {
    path: '/estimates/create',
    name: 'create-estimate',
    component: () => import('@/views/sales/estimates/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Gestion des devis',
      breadcrumb: [
        {
          text: 'Devis',
        },
        {
          text: 'Ajouter un Devis',
          active: true,
        },
      ],
    },
  },
  {
    path: '/estimates/edit/:id',
    name: 'edit-estimate',
    props: true,
    component: () => import('@/views/sales/estimates/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Gestion des devis',
      breadcrumb: [
        {
          text: 'Devis',
        },
        {
          text: 'Modifier un Devis',
          active: true,
        },
      ],
    },
  },
  {
    path: '/estimates/show/:id',
    name: 'show-estimate',
    props: true,
    component: () => import('@/views/sales/estimates/Show.vue'),
    meta: {
      requireLogin: true,
    },
  },

]
