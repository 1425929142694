export default [
  {
    path: '/purchase-orders',
    name: 'purchase-orders',
    component: () => import('@/views/purchase/purchaseOrders/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'List Commandes Achats',
      breadcrumb: [
        {
          text: 'Commande Achat',
        },
        {
          text: 'List Commande Achat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/purchase-orders/create',
    name: 'create-purchase-order',
    component: () => import('@/views/purchase/purchaseOrders/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Bon Achat',
      breadcrumb: [
        {
          text: 'Commande Achat',
        },
        {
          text: 'Bon Achat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/purchase-orders/edit/:id',
    name: 'edit-purchase-order',
    props: true,
    component: () => import('@/views/purchase/purchaseOrders/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Modifier Commande Achat',
      breadcrumb: [
        {
          text: 'Commande Achat',
        },
        {
          text: 'Modifier Commande Achat',
          active: true,
        },
      ],
    },
  },

  {
    path: '/purchase-orders/show/:id',
    name: 'show-purchase-order',
    props: true,
    component: () => import('@/views/purchase/purchaseOrders/Show.vue'),
    meta: {
      requireLogin: true,
    },
  },
  {
    path: '/purchase-orders/print-all',
    name: 'purchase-order-print-all',
    props: true,
    component: () => import('@/views/purchase/purchaseOrders/PrintAll.vue'),
    meta: {
      requireLogin: true,
    },
  },
]
