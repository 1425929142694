export default [
  {
    path: '/bon-de-sortie',
    name: 'exit-vouchers',
    component: () => import('@/views/sales/exitVoucher/Index.vue'),
    meta: {
      pageTitle: 'Bon de sortie',
      breadcrumb: [
        {
          text: 'Bon de sortie',
          active: true,
        },
      ],
      requireLogin: true,
    },
  },
  {
    path: '/bon-de-sortie/creation',
    name: 'create-exit-voucher',
    component: () => import('@/views/sales/exitVoucher/Create.vue'),
    meta: {
      pageTitle: 'Bon de sortie',
      requireLogin: true,
      breadcrumb: [
        {
          text: 'Création',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bon-de-sortie/creation-without-driver/:id',
    name: 'create-exit-voucher-without-driver',
    component: () => import('@/views/sales/exitVoucher/CreateAutoWithoutDriver.vue'),
    meta: {
      pageTitle: 'Bon de sortie',
      requireLogin: true,
      breadcrumb: [
        {
          text: 'Création',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bon-de-sortie/creation-with-driver/:id',
    name: 'create-exit-voucher-with-driver',
    component: () => import('@/views/sales/exitVoucher/CreateAutoWithDriver.vue'),
    meta: {
      pageTitle: 'Bon de sortie',
      requireLogin: true,
      breadcrumb: [
        {
          text: 'Création',
          active: true,
        },
      ],
    },
  },
  {
    path: '/bon-de-sortie/:id',
    name: 'show-exit-voucher',
    component: () => import('@/views/sales/exitVoucher/Show.vue'),
    meta: {
      requireLogin: true,

    },
  },

  {
    path: '/orders/print-all',
    name: 'exit-print-all',
    props: true,
    component: () => import('@/views/sales/exitVoucher/PrintAll.vue'),
    meta: {
      requireLogin: true,
    },
  },

]
