import Vue from 'vue'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    products: [],
    loading: false,
    error: null,
  },
  getters: {
    products: state => state.products,
    loading: state => state.loading,
    error: state => state.error,
  },
  mutations: {
    SET_PRODUCTS(state, payload) {
      state.products = payload
    },
    ADD_PRODUCT(state, payload) {
      state.products.unshift(payload)
    },
    UPDATE_PRODUCT(state, payload) {
      const index = state.products.findIndex(
        product => product.id === payload.id,
      )
      Vue.set(state.products, index, payload)
    },
    DELETE_PRODUCT(state, payload) {
      const index = state.products.findIndex(
        product => product.id === payload,
      )
      state.products.splice(index, 1)
    },
    SET_LOADING: (state, payload) => {
      state.loading = payload
    },
    SET_ERROR: (state, payload) => {
      state.error = payload
    },
    // eslint-disable-next-line no-return-assign
    CLEAR_ERROR: state => (state.error = null),
  },
  actions: {
    async getProducts({ commit }) {
      try {
        commit('SET_LOADING', true)
        const { data } = await axiosIns.get('/products/')
        commit('SET_PRODUCTS', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
      }
    },
    async addProduct({ commit }, payload) {
      try {
        commit('CLEAR_ERROR')
        commit('SET_LOADING', true)
        const { data } = await axiosIns.post('/products/create/', payload)
        commit('ADD_PRODUCT', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async updateProduct({ commit }, payload) {
      try {
        const { data } = await axiosIns.put(
          `/products/update/${payload.id}/`,
          payload,
        )
        commit('UPDATE_PRODUCT', data)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
    async deleteProduct({ commit }, payload) {
      try {
        await axiosIns.delete(`/products/delete/${payload}`)
        commit('DELETE_PRODUCT', payload)
        commit('SET_LOADING', false)
      } catch (error) {
        commit('SET_LOADING', false)
        commit('SET_ERROR', error)
      }
    },
  },
}
