export default [
  // standard orders
  {
    path: '/orders',
    name: 'orders',
    component: () => import('@/views/sales/orders/standard/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Vente',
      breadcrumb: [
        {
          text: 'Commande vente',
        },
        {
          text: 'Liste commande vente',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/create',
    name: 'create-order',
    component: () => import('@/views/sales/orders/standard/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Vente',
      breadcrumb: [
        {
          text: 'Commande Vente',
        },
        {
          text: 'Ajouter Commande Vente',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/edit/:id',
    name: 'edit-order',
    props: true,
    component: () => import('@/views/sales/orders/standard/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Modifier Commande Vente',
      breadcrumb: [
        {
          text: 'Commande Vente',
        },
        {
          text: 'Modifier Commande Vente',
          active: true,
        },
      ],
    },
  },
  {
    path: '/orders/show/:id',
    name: 'show-order',
    props: true,
    component: () => import('@/views/sales/orders/standard/Show.vue'),
    meta: {
      requireLogin: true,
    },
  },

  {
    path: '/orders/print-all',
    name: 'order-print-all',
    props: true,
    component: () => import('@/views/sales/orders/standard/PrintAll.vue'),
    meta: {
      requireLogin: true,
    },
  },
  // custom

  {
    path: '/custom-orders',
    name: 'custom-orders',
    component: () => import('@/views/sales/orders/custom/Index.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Vente',
      breadcrumb: [
        {
          text: 'Commande vente',
        },
        {
          text: 'Liste commande vente',
          active: true,
        },
      ],
    },
  },
  {
    path: '/custom-orders/create',
    name: 'create-custom-order',
    component: () => import('@/views/sales/orders/custom/Create.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Vente',
      breadcrumb: [
        {
          text: 'Commande Vente',
        },
        {
          text: 'Ajouter Commande Vente',
          active: true,
        },
      ],
    },
  },
  {
    path: '/custom-orders/edit/:id',
    name: 'edit-custom-order',
    props: true,
    component: () => import('@/views/sales/orders/standard/Edit.vue'),
    meta: {
      requireLogin: true,
      pageTitle: 'Modifier Commande Vente',
      breadcrumb: [
        {
          text: 'Commande Vente',
        },
        {
          text: 'Modifier Commande Vente',
          active: true,
        },
      ],
    },
  },
  {
    path: '/custom-orders/show/:id',
    name: 'show-custom-order',
    props: true,
    component: () => import('@/views/sales/orders/custom/Show.vue'),
    meta: {
      requireLogin: true,
    },
  },

  {
    path: '/custom-orders/print-all',
    name: 'custom-order-print-all',
    props: true,
    component: () => import('@/views/sales/orders/custom/PrintAll.vue'),
    meta: {
      requireLogin: true,
    },
  },
]
